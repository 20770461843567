import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FadeInLeft from 'components/animation/FadeInLeft';
import FadeInRight from 'components/animation/FadeInRight';
import CustomContainer from '../../CustomContainer';

const Portfolio = () => {
  const DATAS = useStaticQuery(graphql`
    query MyQuery {
      allPortfolioJson {
        nodes {
          index
          slug
          listImage
        }
      }
    }
  `);

  const PORTFOLIOS = DATAS.allPortfolioJson.nodes;

  return (
    <div className="bg-brand-black text-brand-white min-h-screen pt-[70px]">
      <CustomContainer className="py-12 sm:py-16">
        <div className="h-full px-5 sm:px-10">
          <FadeInLeft>
            <div className="flex flex-row justify-start items-center">
              <hr className="w-[50px] sm:w-[70px]" />
              <p className="pl-5 sm:pl-10 text-md sm:text-lg font-light">
                Portfolio
              </p>
            </div>
          </FadeInLeft>
          <FadeInRight>
            <div className="pt-5 xl:pt-3 pb-10 sm:pb-16 w-full flex justify-end">
              <p className="text-right w-full sm:w-[65%] xl:w-[75%] text-sm sm:text-[20px] font-extralight">
                We are proud to have grown together with these extra-mile
                walkers in our family.
              </p>
            </div>
          </FadeInRight>
        </div>
        <div className="sm:px-5">
          <div
            className="grid justify-items-stretch items-stretch
            grid-cols-[1fr] sm:grid-cols-[repeat(3,_1fr)] lg:grid-cols-[repeat(4,_1fr)]"
          >
            {PORTFOLIOS.map((portfolio) => (
              <Link to={`/portfolio/${portfolio?.slug}`} key={portfolio?.index}>
                <div
                  className="h-[170px] sm:h-[150px] xl:h-[170px] mx-12 sm:mx-5 my-5 cursor-pointer
                  hover:brightness-125 hover:shadow-[3px_10px_15px_-3px_rgb(0,0,0,0.5)] duration-500"
                >
                  <img
                    src={portfolio?.listImage}
                    alt="logo-list"
                    className="h-full w-full object-contain bg-[#000]"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default Portfolio;
