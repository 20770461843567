import React from 'react';
import Pages from '../../components/Pages';
import Portfolio from '../../components/pages/portfolio';

const PortfolioPage = () => {
  return (
    <Pages>
      <Portfolio />
    </Pages>
  );
};

export default PortfolioPage;
